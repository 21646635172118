import { FC, ReactNode } from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  paper: {
    width: '100%',
    padding: 20,
  },
}));

interface GridContainerProps {
  paperProps?: any;
  children: ReactNode;
}

export const GridContainer: FC<GridContainerProps> = ({ children, paperProps = {} }) => {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} variant="outlined" className={classes.paper} {...paperProps}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
