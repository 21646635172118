import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/analytics';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import { actionTypes } from 'react-redux-firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

// App
import { onChange as appOnChange } from 'src/store/app';
// import { segmentAnalytics, SEGMENT_CONSTANTS } from 'src/utils/analytics';
import { log } from 'src/utils/index';
import ReduxAPI from 'src/store/api';

const config = {
  apiKey: 'AIzaSyB5dHGutk2Gw0Ll1LrChz5NjBdnEHJaJ1Q',
  authDomain: 'mbele-f470f.firebaseapp.com',
  databaseURL: 'https://mbele-f470f.firebaseio.com',
  projectId: 'mbele-f470f',
  storageBucket: 'mbele-f470f.appspot.com',
  messagingSenderId: '413380485979',
  appId: '1:413380485979:web:434f53f3a1cbf1679ff07e',
  measurementId: 'G-NFMTDN00KT',
};

const firebaseApp = firebase.initializeApp(config);
// firebase.analytics();

const storage = getStorage(firebaseApp);

const RESET_UPLOAD = `@@reactReduxFirebaseCustom/RESET_UPLOAD`;

function reset() {
  return {
    type: RESET_UPLOAD,
  };
}

const DEFAULT_STATE = { payload: {}, meta: {} };
export function storageReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionTypes.FILE_UPLOAD_START: {
      return {
        ...DEFAULT_STATE,
        status: 'UPLOADING',
      };
    }

    case actionTypes.FILE_UPLOAD_PROGRESS: {
      return {
        ...state,
        payload: action.payload,
        meta: action.meta,
      };
    }

    case actionTypes.FILE_UPLOAD_ERROR: {
      return {
        ...DEFAULT_STATE,
        status: 'ERROR',
      };
    }

    case actionTypes.FILE_UPLOAD_COMPLETE: {
      return {
        ...state,
        status: 'PROCESSING',
      };
    }

    case RESET_UPLOAD: {
      return DEFAULT_STATE;
    }

    default: {
      return state;
    }
  }
}

export function uploadVideo(data, file, callback) {
  const videoApi = new ReduxAPI('video', '/api/v1/source/video/');

  return (dispatch, getState) => {
    const state = getState();
    const user = state.userAuth.detail;
    dispatch(videoApi.postRequest(data)).then(videoResponse => {
      if (videoResponse.id) {
        // if (user.fb_app && user.fb_secret) {
        const storagePath = `/videos/web/${user.fb_uid || user.id}/${videoResponse.uuid}`;

        const storageRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          'state_changed',
          snapshot => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            if (progress < 95) {
              dispatch(appOnChange('uploadProgress', { percent: progress, state: snapshot.state }));
            } else {
              dispatch(appOnChange('uploadProgress', null));
            }

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused');
                break;
              case 'running':
                console.log('Upload is running');
                break;
            }
          },
          error => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
              dispatch(
                videoApi.patchRequest(
                  { firebase_url: downloadURL, media_type: data.media_type },
                  `${videoResponse.id}/video_format`,
                ),
              ).then(resp => {
                dispatch(reset());
                callback && callback(videoResponse);
                // segmentAnalytics.track(SEGMENT_CONSTANTS.VIDEO_UPLOADED, {
                //   video_id: videoResponse.id,
                // });
              });
            });
          },
        );

        // getFirebase()
        //   .uploadFile(storagePath, file, null, {
        //     progress: true, // show progress of upload
        //     video_id: videoResponse.id,
        //   })
        //   .then(uploadResponse => {
        //     uploadResponse.uploadTaskSnapshot.ref.getDownloadURL().then(downloadURL => {
        //       dispatch(
        //         videoApi.patchRequest(
        //           { firebase_url: downloadURL },
        //           `${videoResponse.id}/video_format`,
        //         ),
        //       ).then(resp => {
        //         dispatch(reset());
        //         callback && callback(videoResponse);
        //         segmentAnalytics.track(SEGMENT_CONSTANTS.VIDEO_UPLOADED, {
        //           video_id: videoResponse.id,
        //         });
        //       });
        //     });
        //   });
        // }
      }
    });
  };
}

export const requestFirebaseNotificationPermission = (user, dispatch) =>
  new Promise((resolve, reject) => {
    try {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(firebaseToken => {
          if (user && (!user.fcm_devices || user.fcm_devices.indexOf(firebaseToken) === -1)) {
            const fcmDeviceApi = new ReduxAPI('fcmDevice', '/api/v1/notification/devices/');
            const userAuthApi = new ReduxAPI('userAuth', '/api/v1/rest-auth/');

            const data = {
              type: 'web',
              registration_id: firebaseToken,
              user_id: user.id,
            };

            dispatch(fcmDeviceApi.postRequest(data)).then(resp => {
              dispatch(userAuthApi.getDetailRequest('user'));
            });
          }
          resolve(firebaseToken);
        })
        .catch(err => {
          reject(err);
        });
    } catch (err) {
      log.exception(err);
    }
  });

export default firebase;
