import React from 'react';

import { Typography, Grid, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames';

import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import { Steps } from '../PreJoinScreens';
import { useAppState } from 'src/state';
import useChatContext from 'src/hooks/useChatContext/useChatContext';
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext';
import UsernameLink from 'src/components/User/UsernameLink';
import { buttonPrimary, buttonDanger } from 'src/utils/classnames';
import { APP_URL } from 'src/utils/constants';

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

function RenderJoinText() {
  const { requestError, request } = useAppState();
  if (window.req_uuid && request?.id) {
    const { requested, created_by } = request;
    return (
      <>
        <div className="tw-text-2xl tw-text-gray-800 tw-mb-2 tw-font-medium">Ready to join?</div>
        <div className="tw-text-lg tw-text-gray-800">
          Call between <UsernameLink username={requested?.username} /> {'&'}{' '}
          <UsernameLink username={created_by?.username} />
        </div>
      </>
    );
  } else if (requestError) {
    return (
      <>
        <div className="tw-text-2xl tw-mb-2 tw-text-red-600 tw-font-medium">Unable to join!</div>
        <div className="tw-text-lg tw-text-red-600">{requestError?.msg}</div>
      </>
    );
  } else if (window.req_uuid) {
    return (
      <>
        <div className="tw-text-xl tw-mb-5 tw-text-gray-800 tw-font-medium">
          Retrieving meeting invite...
        </div>
        <CircularProgress />
      </>
    );
  }
  return null;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  setStep,
}: DeviceSelectionScreenProps) {
  const { getToken, isFetching, request } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons =
    isFetching || isAcquiringLocalTracks || isConnecting || (window.req_uuid && !request);

  const handleJoin = () => {
    getToken(name, roomName).then(token => {
      videoConnect(token);
      chatConnect(token);
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: '100%' }}
      >
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-w-full sm:tw-px-8 sm:tw-w-5/6">
        <Grid container justifyContent="center">
          <Grid item md={8} sm={12} xs={12}>
            <LocalVideoPreview identity={name} disableButtons={!!disableButtons} />
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-mt-4 sm:tw-mt-0">
              <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
                <RenderJoinText />
              </div>

              <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-items-center tw-py-4">
                <span className="tw-w-5/6 tw-my-2">
                  <Button
                    variant="contained"
                    color="primary"
                    data-cy-join-now
                    onClick={handleJoin}
                    disabled={!!disableButtons}
                    className={classnames('tw-w-full', buttonPrimary)}
                  >
                    Join Now
                  </Button>
                </span>

                <span className="tw-w-5/6 tw-my-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classnames('tw-w-full', buttonDanger)}
                    onClick={() => setStep(Steps.roomNameStep)}
                    href={APP_URL}
                  >
                    Cancel
                  </Button>
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
