import { FC, createContext, useState, useEffect, PropsWithChildren } from 'react';

import { useAppState } from 'src/state';

type ViewType = 'default' | 'whiteboard' | 'code' | 'doc';

export type IState = {
  view: ViewType;
  toolUrl?: string | null;
};

export type IDispatch = {
  setView: (value: ViewType) => void;
  setUrl: (value: string) => void;
};

const InitialState: Partial<IState> = { view: 'default' };

export type ToolContextProps = IState & IDispatch;
export const ToolContext = createContext<ToolContextProps>(InitialState as ToolContextProps);

const ToolProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [view, setView] = useState<ViewType>('default');
  const [url, setUrl] = useState('');
  const { request } = useAppState();

  useEffect(() => {
    if (view === 'whiteboard') {
      setUrl(request?.whiteboard_url || '');
    } else if (view === 'code') {
      setUrl(request?.coding_url || '');
    }
  }, [view]);

  const value: ToolContextProps = {
    view,
    setView,
    toolUrl: url,
    setUrl,
  };
  return <ToolContext.Provider value={value}>{children}</ToolContext.Provider>;
};

export default ToolProvider;
