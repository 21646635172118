// @ts-nocheck
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { ErrorBoundary } from 'react-error-boundary';

import AppStateProvider, { useAppState } from './state';
import ThemeConfig from 'src/theme';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

import AuthPage from './components/Auth/index';
import ChatProvider from './context/ChatProvider';
import VideoProvider from './context/VideoProvider';
import App from './App';
import ToolProvider from './context/ToolProvider';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';

import './index.css';
import './types';
import { API_URL } from 'src/utils/constants';
import store from 'src/store/configure';

const link = createHttpLink({
  uri: `${API_URL}/api/graphql`,
  credentials: 'include',
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

interface CircularLoadingProps {
  height?: string | number;
}

const CircularLoading: FC<CircularLoadingProps> = () => {
  return (
    <Grid container justifyContent="center" style={{ marginBottom: 25 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CircularProgress style={{ marginTop: 75 }} />
      </Grid>
    </Grid>
  );
};

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <UnsupportedBrowserWarning>
      <ToolProvider>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <ChatProvider>
            <App />
          </ChatProvider>
        </VideoProvider>
      </ToolProvider>
    </UnsupportedBrowserWarning>
  );
};

function Loading() {
  return <CircularLoading height="100vh" />;
}

function AppContainer() {
  const { user, initialized } = useAppState();

  if (!initialized) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (!user) {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/register" element={<AuthPage />} />
            <Route path="/register/:username" element={<AuthPage />} />
            <Route path=":requestId" element={<AuthPage />} />
          </Routes>
        </Suspense>
      </Router>
    );
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<VideoApp />} />
          <Route path="/room/:URLRoomName" element={<VideoApp />} />
          <Route path=":requestId" element={<VideoApp />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<div>We encountered an unexpected error.</div>}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <ThemeConfig>
            <AppStateProvider>
              <SnackbarProvider>
                <AppContainer />
              </SnackbarProvider>
            </AppStateProvider>
          </ThemeConfig>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);
