import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../../context/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { useTool } from 'src/hooks';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowY: 'auto',
      background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        gridArea: '2 / 1 / 3 / 3',
        overflowY: 'initial',
        overflowX: 'auto',
        display: 'flex',
      },
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    innerScrollContainer: {
      width: `calc(${theme.sidebarWidth}px - 3em)`,
      padding: '1.5em 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: `${theme.sidebarMobilePadding}px`,
        display: 'flex',
      },
    },
    draggableContainer: {
      width: 250,
      height: 150,
      zIndex: 100,
      cursor: 'grab',
    },
  }),
);

interface DragContainerType {
  index: number;
  children: ReactNode;
}

const DragContainer: FC<DragContainerType> = ({ children, index }) => {
  const classes = useStyles();
  const documentElement = document.documentElement;
  const wrapperWidth = window.innerWidth || documentElement.clientWidth;

  return (
    <Draggable defaultPosition={{ x: wrapperWidth - 260, y: index * 250 + 5 }}>
      <div className={classes.draggableContainer}>{children}</div>
    </Draggable>
  );
};

export default function ParticipantList() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const { view } = useTool();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  if (participants.length === 0 && view === 'default') return null; // Don't render this component if there are no remote participants and the view is the default view

  if (view !== 'default') {
    // Make the components easily draggable
    return (
      <>
        <DragContainer index={0}>
          <Participant participant={localParticipant} isLocalParticipant={true} />
        </DragContainer>

        {participants.map((participant, index) => {
          return (
            <DragContainer key={participant.sid} index={index + 1}>
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                onClick={() => ''}
                hideParticipant={false}
              />
            </DragContainer>
          );
        })}
      </>
    );
  }
  return (
    <aside
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div className={classes.scrollContainer}>
        <div className={classes.innerScrollContainer}>
          <Participant participant={localParticipant} isLocalParticipant={true} />
          {participants.map(participant => {
            const isSelected = participant === selectedParticipant;
            const hideParticipant =
              participant === mainParticipant &&
              participant !== screenShareParticipant &&
              !isSelected &&
              view === 'default';
            return (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                onClick={() => setSelectedParticipant(participant)}
                hideParticipant={hideParticipant}
              />
            );
          })}
        </div>
      </div>
    </aside>
  );
}
