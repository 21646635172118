export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'MbeleVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'MbeleVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'MbeleVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'MbeleVideoApp-selectedBackgroundSettings';

export const APP = process.env.REACT_APP_APP || '';
export const API_URL =
  process.env.NODE_ENV === 'production' ? `https://api.${APP}.io` : 'https://localhost:3000';
export const APP_URL =
  process.env.NODE_ENV === 'production' ? `https://app.${APP}.io` : 'https://localhost:3000';
