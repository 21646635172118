import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import Fab from '@mui/material/Fab';

import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  fab?: boolean;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Fab
      className={clsx(props.className, 'tw-m-2', !isVideoEnabled && 'tw-bg-red-500')}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
      data-cy-audio-toggle
      size="medium"
    >
      {isVideoEnabled ? (
        <VideocamIcon height="30" width="30" />
      ) : (
        <VideocamOffIcon height="30" width="30" style={{ color: 'white' }} />
      )}
    </Fab>
  );
}
