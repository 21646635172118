import { useEffect, useState, useRef, FC } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash.sortby';
import ContentLoader from 'react-content-loader';

import Scrollbar from 'src/components/Minimals/Scrollbar';
import LightboxModal from 'src/components/Minimals/LightboxModal';
import { CommentType, UserAuthType, FileType } from 'src/types/index';
import { userAuth as userAuthApi, comment as commentApi } from 'src/store/index';
import ChatMessageItem from './ChatMessageItem';
import { GridContainer } from 'src/components/Common/index';

export const VideoCommentListLoading: FC = () => {
  const list = [];
  for (let i = 1; i <= 2; i++) {
    list.push(
      <GridContainer key={i}>
        <ContentLoader
          height={75}
          width="100%"
          speed={1}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <circle cx="27" cy="27" r="25" />
          <rect x="70" y="0" rx="3" ry="3" width="80%" height="20" />
          <rect x="70" y="40" rx="3" ry="3" width="50%" height="20" />
        </ContentLoader>
      </GridContainer>,
    );
  }
  return <>{list}</>;
};

export default function ChatMessageList(): JSX.Element {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);

  const comments: CommentType[] = useSelector((state: any) => state[commentApi.APP_NAME].list);
  const commentInitialized = useSelector((state: any) => state[commentApi.APP_NAME].initialized);
  const user: UserAuthType = useSelector((state: any) => state[userAuthApi.APP_NAME].detail);

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [comments.length]);

  const handleOpenLightbox = (file: FileType) => {
    setOpenLightbox(true);
    setSelectedFile(file);
  };

  if (!commentInitialized) {
    return (
      <div
        style={{ position: 'absolute', top: 2, left: 0, right: 0, bottom: 57, overflow: 'scroll' }}
      >
        <VideoCommentListLoading />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 5,
          left: 0,
          right: 0,
          bottom: 57,
          overflow: 'scroll',
          marginTop: 14,
        }}
      >
        <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, height: 1 }}>
          {sortBy(comments, x => x.created_at).map(message => (
            <ChatMessageItem
              key={message.id}
              message={message}
              onOpenLightbox={handleOpenLightbox}
              user={user}
            />
          ))}
        </Scrollbar>
      </div>

      <LightboxModal
        file={selectedFile}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </>
  );
}
