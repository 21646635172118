import React from 'react';

export default function WarningIcon() {
  return (
    <svg
      width="16px"
      height="14px"
      viewBox="0 0 16 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-273.000000, -162.000000)" fill="#E46216">
          <g transform="translate(251.000000, 143.000000)">
            <g transform="translate(22.000000, 19.000000)">
              <path d="M6.68405227,0.743750848 C7.26590697,-0.247916949 8.73410873,-0.247916949 9.31596344,0.743750848 L9.31596344,0.743750848 L15.7980583,11.7915293 C16.3754418,12.775573 15.6458879,14 14.4821027,14 L14.4821027,14 L1.5178827,14 C0.354135396,14 -0.375441293,12.775573 0.201934693,11.7915293 L0.201934693,11.7915293 Z M8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 Z M8.01011035,5 L7.98974671,5 L7.87816923,5.00681158 C7.32928042,5.07427655 6.92320065,5.63308608 7.01229161,6.24395862 L7.01229161,6.24395862 L7.43992821,8.48168391 L7.46058982,8.57746523 C7.53403676,8.82448556 7.74990819,9 8.00332247,9 L8.00332247,9 L8.09436762,8.9919759 C8.33199007,8.94972242 8.5237268,8.74495557 8.55992884,8.48168391 L8.55992884,8.48168391 L8.98756545,6.24395862 L8.99896034,6.12268979 C9.02431547,5.52149772 8.58283795,5.00694173 8.01011035,5 L8.01011035,5 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
