import React from 'react';
import clsx from 'clsx';

import Fab from '@mui/material/Fab';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  fab?: boolean;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Fab
      className={clsx(props.className, 'tw-m-2', !isAudioEnabled && 'tw-bg-red-500')}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
      size="medium"
    >
      {isAudioEnabled ? (
        <MicIcon height="30" width="30" />
      ) : (
        <MicOffIcon height="30" width="30" style={{ color: 'white' }} />
      )}
    </Fab>
  );
}
