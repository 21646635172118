import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, Stack } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';

// @types
import { SendMessage } from 'src/types/chat';
import ChatMessageList from 'src/components/Chat/ChatMessageList';
import ChatMessageInput from 'src/components/Chat/ChatMessageInput';
import { comment as commentApi } from 'src/store/index';
import { useAppState } from 'src/state';
import useChatContext from 'src/hooks/useChatContext/useChatContext';
import { useTool } from 'src/hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    hide: {
      display: 'none',
    },
    toolView: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: 300,
    },
  }),
);

const MySwal = withReactContent(Swal);

export default function ChatWindow(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { request } = useAppState();
  const { isChatWindowOpen } = useChatContext();
  const { view } = useTool();

  useEffect(() => {
    dispatch(commentApi.reset());

    if (request?.id) {
      dispatch(commentApi.setGetParams({ request_id: request.id }));
      // @ts-ignore
      dispatch(commentApi.getRequest());
    }
  }, [request?.id]);

  const handleSendMessage = async ({ message }: SendMessage) => {
    if (!request?.id) {
      MySwal.fire({
        title: 'The request was not set',
        text: '',
      });
      return;
    }
    try {
      const data = {
        request_id: request.id,
        comment: message,
      };
      // @ts-ignore
      await dispatch(commentApi.postRequest(data));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <aside
      className={clsx(
        classes.chatWindowContainer,
        {
          [classes.hide]: !isChatWindowOpen,
          [classes.toolView]: view !== 'default',
        },
        'tw-bg-white tw-shadow',
      )}
    >
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1, position: 'relative' }}>
          <ChatMessageList />

          <Divider style={{ position: 'absolute', bottom: 55, right: 0, left: 0 }} />

          <ChatMessageInput onSend={handleSendMessage} />
        </Stack>
      </Box>
    </aside>
  );
}
