import { useEffect } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useAppState } from './state';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import MenuBar from './components/MenuBar/MenuBar';
import Room from './components/Room/Room';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { API_URL } from 'src/utils/constants';
import Axios from 'src/utils/axios';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App(): JSX.Element {
  const roomState = useRoomState();
  const { user, request, setRequest, setRequestError } = useAppState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [authToken, _] = useLocalStorage('authToken', '');

  useEffect(() => {
    if (!user?.id) return;

    Axios.get(`${API_URL}/api/meet/${params?.requestId}/`, {
      headers: { Authorization: `Token ${authToken}` },
    })
      .then(resp => {
        if (resp.data && resp.data.id) {
          setRequest(resp.data);
        }
      })
      .catch(() => {
        enqueueSnackbar('Something went wrong, we could not retrieve your request.', {
          variant: 'error',
        });
        setRequestError({ msg: 'Oops something went wrong' });
      });
  }, [user?.id]);

  if (!request?.id) {
    return (
      <Container style={{ height, background: 'white' }}>
        <CircularProgress
          size={24}
          className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-screen"
        />
      </Container>
    );
  }

  return (
    <Container style={{ height, background: 'white' }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
