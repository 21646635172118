import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

interface Props {
  title: string;
  icon?: SweetAlertIcon;
  text: string;
  footer?: string;
}
export default function useSweetAlert() {
  const triggerAlert = ({ title, text, icon, footer }: Props) => {
    MySwal.fire({ title, icon, text, footer });
  };

  return {
    triggerAlert,
  };
}
