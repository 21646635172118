import { useState } from 'react';
import classnames from 'classnames';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// core components
import Axios from 'src/utils/axios';
import CustomInput from './CustomInput';
import { useAppState } from 'src/state';
import { API_URL } from 'src/utils/constants';
import { buttonPrimary, buttonMuted } from 'src/utils/classnames';
import { onChange as appOnChange } from 'src/store/app';
import { useDispatch, useLocalStorage } from 'src/hooks';

interface CredentialsType {
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
}

interface ErrorType {
  all?: string;
  email?: string;
  password?: string;
  password1?: string;
  password2?: string;
}

const useStyles = makeStyles({
  grid: {
    marginRight: '-15px',
    marginLeft: '-15px',
    width: 'auto',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface EmailAuthProps {
  onSuccess?: VoidFunction;
  isLogin: boolean;
}

export default function LoginPage({ isLogin, onSuccess }: EmailAuthProps) {
  const [credentials, setCredentials] = useState<CredentialsType>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ErrorType>({});
  const { setAuthUser } = useAppState();
  const dispatch = useDispatch();
  const [_, setStorageAuthToken] = useLocalStorage('authToken', '');

  const classes = useStyles();

  let enabled = false;
  if (isLogin) {
    enabled = !!(credentials.email && credentials.password);
  } else {
    enabled = !!(credentials.email && credentials.password1 && credentials.password2);
  }

  function handleLogin() {
    setIsLoading(true);
    Axios.post(
      isLogin ? `${API_URL}/api/v1/rest-auth/login/` : `${API_URL}/api/v1/rest-auth/registration/`,
      credentials,
    )
      .then((resp: any) => {
        setIsLoading(false);

        if (resp?.data?.key) {
          dispatch(appOnChange('authToken', resp?.data?.key));
          setStorageAuthToken(resp?.data?.key);

          Axios.get(`${API_URL}/api/v1/rest-auth/user/`, {
            headers: { Authorization: `Token ${resp?.data?.key}` },
          }).then(resp => {
            setIsLoading(false);
            setAuthUser(resp.data);
            onSuccess?.();
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 400 && err.response.data) {
          const { email, password, password1, password2, non_field_errors } = err.response.data;
          const _error: ErrorType = {};
          if (email) _error.email = email[0];
          if (password) _error.password = password[0];
          if (password1) _error.password1 = password1[0];
          if (password2) _error.password2 = password2[0];
          if (non_field_errors) _error.all = non_field_errors[0];
          setError(_error);
        } else {
          console.error(err);
          setError({ all: 'Oops sorry. Something went wrong.' });
        }
        setIsLoading(false);
      });
  }

  function onKeyDown(e: any) {
    if (e.keyCode === 13 && enabled) {
      handleLogin();
    }
  }

  const passwordComponent = isLogin ? (
    <>
      <CustomInput
        labelText={error.password ? error.password : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password: ev.target.value }),
          onKeyDown,
        }}
        error={error.password}
      />
    </>
  ) : (
    <>
      <CustomInput
        labelText={error.password1 ? error.password1 : 'Password'}
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password1,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password1: ev.target.value }),
          onKeyDown,
        }}
        error={error.password1}
      />

      <CustomInput
        labelText={error.password2 ? error.password2 : 'Confirm Password'}
        id="pass2"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          autoComplete: 'off',
          value: credentials.password2,
          variant: 'outlined',
          onChange: (ev: any) => setCredentials({ ...credentials, password2: ev.target.value }),
          onKeyDown,
        }}
        error={error.password2}
      />
    </>
  );

  let renderErrorAll;
  if (error.all) {
    renderErrorAll = (
      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 15 }}>
        <Typography id="error-text" variant="body2" gutterBottom color="error">
          {error.all}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" className={classes.grid}>
      {renderErrorAll}
      <Grid item xs={12} sm={12} md={12}>
        <CustomInput
          labelText={error.email ? error.email : 'Email...'}
          id="email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'email',
            onChange: (ev: any) => setCredentials({ ...credentials, email: ev.target.value }),
            value: credentials.email,
            variant: 'outlined',
            onKeyDown,
          }}
          error={error.email}
        />

        {passwordComponent}
      </Grid>

      <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
        <Button
          // color={enabled ? 'primary' : 'default'}
          disabled={!enabled || isLoading}
          onClick={handleLogin}
          id="submit-auth-button"
          className={classnames(enabled ? buttonPrimary : buttonMuted, 'tw-w-full')}
        >
          {isLogin ? 'Login' : 'Sign Up'}
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </Grid>
    </Grid>
  );
}
