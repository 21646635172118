import { useContext } from 'react';
import { ToolContext } from 'src/context/ToolProvider';

export default function useToolContext() {
  const context = useContext(ToolContext);
  if (!context) {
    throw new Error('useToolContext must be used within a ToolProvider');
  }
  return context;
}
