import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
// import micFill from '@iconify/icons-eva/mic-fill';
import roundSend from '@iconify/icons-ic/round-send';
// import attach2Fill from '@iconify/icons-eva/attach-2-fill';
// import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import { styled } from '@mui/material/styles';
import { Input, Divider, IconButton, InputAdornment } from '@mui/material';
// @types
import { SendMessage } from 'src/types/chat';
//
import EmojiPicker from 'src/components/Minimals/EmojiPicker';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  // position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
  position: 'absolute',
  bottom: 0,
  width: '100%',
}));

// ----------------------------------------------------------------------

type ChatMessageInputProps = {
  disabled?: boolean;
  onSend: (data: SendMessage) => void;
};

export default function ChatMessageInput({ onSend, disabled }: ChatMessageInputProps): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');

  // const handleAttach = () => {
  //   fileInputRef.current?.click();
  // };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // handleSend();
    }
  };

  const handleSend = () => {
    if (!message) {
      return '';
    }
    if (onSend) {
      onSend({
        conversationId: uuidv4(),
        messageId: uuidv4(),
        message,
        contentType: 'text',
        attachments: [],
        createdAt: new Date(),
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
      });
    }
    return setMessage('');
  };

  return (
    <RootStyle>
      <Input
        disabled={disabled}
        fullWidth
        value={message}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={e => setMessage(e.target.value)}
        placeholder="Type a message"
        startAdornment={
          <InputAdornment position="start">
            <EmojiPicker disabled={disabled} value={message} setValue={setMessage} />
          </InputAdornment>
        }
        // endAdornment={
        //   <Box sx={{ flexShrink: 0, mr: 1.5, '& > *': { mx: 0.5 } }}>
        //     <IconButton disabled={disabled} size="small" onClick={handleAttach}>
        //       <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
        //     </IconButton>
        //     <IconButton disabled={disabled} size="small" onClick={handleAttach}>
        //       <Icon icon={attach2Fill} width={24} height={24} />
        //     </IconButton>
        //     <IconButton disabled={disabled} size="small">
        //       <Icon icon={micFill} width={24} height={24} />
        //     </IconButton>
        //   </Box>
        // }
        sx={{ height: '100%' }}
        multiline
        minRows={2}
        maxRows={2}
      />

      <Divider orientation="vertical" flexItem />

      <IconButton color="primary" disabled={!message} onClick={handleSend} sx={{ mx: 1 }}>
        <Icon icon={roundSend} width={24} height={24} />
      </IconButton>

      <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
    </RootStyle>
  );
}
