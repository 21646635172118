import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// core components
import MainComponent from './components/Main';
import { useAppState } from 'src/state';

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
      marginRight: '10%',
    },
  },
}));

export default function AuthPage() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(false);
  const [isLogin, setIsLogin] = useState(location.pathname === '/login');
  const params = useParams();
  const { requestId, setRequestId } = useAppState();

  useEffect(() => {
    if (params?.requestId) {
      setRequestId(params?.requestId);
    }
  }, [params?.requestId]);

  return (
    <div className={classes.gridContainer}>
      <MainComponent
        onSuccess={() => {
          if (requestId) {
            navigate(`/${requestId}`);
          }
        }}
        onClose={() => {
          navigate('/');
        }}
        setIsEmail={(value: boolean) => {
          setIsEmail(value);
        }}
        setIsLogin={(value: boolean) => {
          setIsLogin(value);
          if (value) {
            navigate('/login');
          } else {
            navigate('/register');
          }
        }}
        isLogin={isLogin}
        isEmail={isEmail}
      />
    </div>
  );
}
