import React from 'react';
import { makeStyles } from '@mui/styles';
import { useAppState } from 'src/state';
import mbeleLogo from 'src/images/mbele-logo.png';
import profilePlaceholder from 'src/images/profile-placeholder.png';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    flex: '1',
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const Navbar = () => {
  const { user } = useAppState();

  return (
    <nav className="tw-bg-white">
      <div className="tw-max-w-full tw-mx-auto tw-px-2 sm:tw-px-6 lg:tw-px-8">
        <div className="tw-relative tw-flex tw-items-center tw-justify-between tw-h-16">
          <div className="tw-flex-1 tw-flex tw-items-center tw-justify-start sm:tw-items-stretch">
            <div className="tw-flex-shrink-0 tw-flex tw-items-center">
              <img
                className="tw-hidden lg:tw-block tw-h-20 tw-w-auto"
                src={mbeleLogo}
                alt="Workflow"
              />
            </div>
          </div>
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2 sm:tw-static sm:tw-inset-auto sm:tw-ml-6 sm:tw-pr-0">
            <div className="tw-ml-3 tw-relative">
              <div>
                <button
                  className="tw-bg-gray-800 tw-flex tw-text-sm tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-offset-gray-800 focus:tw-ring-white"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <img
                    className="tw-h-12 tw-w-12 tw-rounded-full"
                    src={user?.profile?.image || profilePlaceholder}
                    alt=""
                  />
                </button>
              </div>

              {/* <div
                className="hidden origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                  Your Profile
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                  Settings
                </a>
                <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                  Sign out
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
const IntroContainer = (props: IntroContainerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Navbar />

      <div className="tw-h-full sm:tw-h-5/6 tw-flex tw-py-4 sm:tw-items-center tw-bg-white">
        <div className={classes.container}>
          {props.children}
          {props.subContent && (
            <div className={classes.subContentContainer}>{props.subContent}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
