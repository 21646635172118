import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { ErrorCallback } from '../../../types';
import { useSweetAlert } from 'src/hooks/useAlert';

// https://support.google.com/meet/answer/7380413?hl=en-GB&ref_topic=7290455#presentx
interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);
  const { triggerAlert } = useSweetAlert();

  const shareScreen = useCallback(() => {
    console.log('shareScreen');
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: true,
      })
      .then(stream => {
        const track = stream.getTracks()[0];

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room!.localParticipant
          .publishTrack(track, {
            name: 'screen', // Tracks can be named to easily find them later
            priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then(trackPublication => {
            stopScreenShareRef.current = () => {
              room!.localParticipant.unpublishTrack(track);
              // TODO: remove this if the SDK is updated to emit this event
              room!.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch(onError);
      })
      .catch(error => {
        triggerAlert({
          title: "Can't share your screen",
          icon: 'error',
          text: 'Mbele might not have screen-recording permission on your computer.',
          footer:
            'Contact&nbsp;<a href="mailto:support@mbele.io" target="_blank">support@mbele.io</a>&nbsp;for support.',
        });
        // const msg =
        // 'Mbele might not have screen-recording permission on your computer. Go to System Preferences. For more help, go to Issues presenting';
        // onError(new Error('Mbele might not have screen-recording permission on your computer.'));
        console.error(error);
        // Don't display an error if the user closes the screen share dialog
        // if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
        //   onError(error);
        // }
      });
  }, [room, onError, triggerAlert]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current();
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
