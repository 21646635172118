import React from 'react';
import classnames from 'classnames';

import AvatarIcon from '../../../../icons/AvatarIcon';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

import ToggleAudioButton from '../../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../../Buttons/ToggleVideoButton/ToggleVideoButton';
import SettingsMenu from '../SettingsMenu/SettingsMenu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
    // background: 'black',
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  identityContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.18em 0.3em',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
}));

export default function LocalVideoPreview({
  identity,
  disableButtons,
}: {
  identity: string;
  disableButtons?: boolean;
}) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack;

  return (
    <div
      className={classnames(classes.container, 'tw-rounded-md', 'tw-bg-gray-900')}
      style={{ minHeight: 350 }}
    >
      <div className={classnames(classes.innerContainer)}>
        <span className="tw-absolute tw-z-10 tw-top-0 tw-right-0">
          <SettingsMenu />
        </span>

        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classnames(classes.avatarContainer, 'tw-bg-gray-900')}>
            <AvatarIcon />
          </div>
        )}
      </div>

      <div className={classnames(classes.identityContainer, 'tw-w-full')}>
        <span className="tw-flex tw-px-4 tw-py-2 sm:tw-py-5 tw-text-white tw-m-0 tw-items-center tw-justify-center tw-w-full">
          <span className="tw-absolute tw-left-3 tw-bottom-4 sm:tw-bottom-7 tw-flex">
            <LocalAudioLevelIndicator />
            <Typography variant="body1" color="inherit" component="span">
              {identity}
            </Typography>
          </span>

          <span className="tw-flex tw-flex-row tw-w-48 tw-justify-evenly">
            <ToggleAudioButton disabled={disableButtons} fab />
            <ToggleVideoButton disabled={disableButtons} fab />
          </span>
        </span>
      </div>
    </div>
  );
}
//
