import axios from 'axios';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;
export default axios;

export const getHeaders = (state: any) => {
  const headers = { Authorization: '' };
  const authToken = state.app && state.app.authToken;
  if (authToken) headers.Authorization = `Token ${authToken}`;
  return headers;
};
