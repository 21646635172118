import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import Fab from '@mui/material/Fab';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '&[disabled]': {
        color: '#bbb',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  }),
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare, isSharingScreen, room } = useVideoContext();
  const disableScreenShareButton =
    screenShareParticipant && screenShareParticipant !== room?.localParticipant; // only current user can stop screen sharing
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  return (
    <Fab
      className={clsx(
        classes.button,
        'tw-m-2',
        isSharingScreen && 'tw-bg-red-500',
        isDisabled && 'tw-bg-gray-600',
      )}
      disabled={isDisabled}
      onClick={toggleScreenShare}
      data-cy-screenshare-toggle
      style={{ outline: 'none' }}
      size="medium"
    >
      {!isSharingScreen ? (
        <ScreenShareIcon height="30" width="30" />
      ) : (
        <StopScreenShareIcon height="30" width="30" style={{ color: 'white' }} />
      )}
    </Fab>
  );
}
