// ----------------------------------------------------------------------

interface CustomStyle {
  sidebarWidth?: number;
  sidebarMobileHeight?: number;
  brand: string;
  footerHeight: number;
  mobileTopBarHeight: number;
  mobileFooterHeight: number;
  sidebarMobilePadding: number;
  participantBorderWidth: number;
  rightDrawerWidth?: number;
}

declare module '@mui/material/styles' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
  }
}

const custom: CustomStyle = {
  brand: '#E22525',
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  rightDrawerWidth: 320,
};

export default custom;
