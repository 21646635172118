import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import MoreIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from 'src/icons/SettingsIcon';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import TerminalIcon from '@mui/icons-material/Terminal';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import {
  Button,
  styled,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Hidden,
} from '@mui/material';
import { Theme } from '@mui/material/styles';

// import { isSupported } from '@twilio/video-processors';
import { VideoRoomMonitor } from '@twilio/video-room-monitor';

import AboutDialog from 'src/components/AboutDialog/AboutDialog';
import DeviceSelectionDialog from 'src/components/DeviceSelectionDialog/DeviceSelectionDialog';
import BackgroundIcon from 'src/icons/BackgroundIcon';
import InfoIconOutlined from 'src/icons/InfoIconOutlined';
import StartRecordingIcon from 'src/icons/StartRecordingIcon';
import StopRecordingIcon from 'src/icons/StopRecordingIcon';
import { useAppState } from 'src/state';
import useChatContext from 'src/hooks/useChatContext/useChatContext';
import useIsRecording from 'src/hooks/useIsRecording/useIsRecording';
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext';
import { useTool } from 'src/hooks';
import FlipCameraIcon from 'src/icons/FlipCameraIcon';
import useFlipCameraToggle from 'src/hooks/useFlipCameraToggle/useFlipCameraToggle';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles({
  moreIcon: {
    minWidth: 30,
    backgroundColor: 'white',
    padding: 5,
    marginRight: 10,
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.7,
    },
  },
});

export default function Menu(props: { buttonClassName?: string }): JSX.Element {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const isSupported = false;

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType, request } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  const { view, setView } = useTool();

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={clsx(props.buttonClassName, classes.moreIcon)}
        data-cy-more-button
        component="span"
        size="medium"
      >
        <MoreIcon />
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography variant="body1">Backgrounds</Typography>
          </MenuItem>
        )}

        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )}

        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>
              {isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}
            </IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )}

        <Hidden smDown>
          <MenuItem
            onClick={() => {
              VideoRoomMonitor.toggleMonitor();
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <SearchIcon style={{ fill: '#707578', width: '0.9em' }} />
            </IconContainer>
            <Typography variant="body1">Room Monitor</Typography>
          </MenuItem>
        </Hidden>

        {view !== 'whiteboard' ? (
          <MenuItem onClick={() => setView('whiteboard')}>
            <IconContainer>
              <CastForEducationIcon style={{ fill: '#707578', width: '0.9em' }} />
            </IconContainer>
            <Typography variant="body1">Whiteboard</Typography>
          </MenuItem>
        ) : null}

        {request?.coding_url ? (
          <MenuItem href={request.coding_url} target="_blank" component="a">
            {/* // <MenuItem onClick={() => setView('code')}> */}
            <IconContainer>
              <TerminalIcon style={{ fill: '#707578', width: '0.9em' }} />
            </IconContainer>
            <Typography variant="body1">Coding</Typography>
          </MenuItem>
        ) : null}

        {view !== 'default' ? (
          <MenuItem onClick={() => setView('default')}>
            <IconContainer>
              <VideoCallIcon style={{ fill: '#707578', width: '0.9em' }} />
            </IconContainer>
            <Typography variant="body1">Video</Typography>
          </MenuItem>
        ) : null}

        <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem>
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
