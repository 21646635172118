// Npm
import { createSelector } from 'reselect';
import Axios, { getHeaders } from 'src/utils/axios';
import { AxiosError } from 'axios';
import isEmpty from 'lodash.isempty';
import uniqBy from 'lodash.uniqby';
import sortBy from 'lodash.sortby';

// App
import { notification } from './notification';
import { findAndReplace, generateErrorDict, log, encodeUrl } from 'src/utils/index';
import { API_URL } from 'src/utils/constants';

const DEFAULT_STATE = {
  list: [],
  detail: {},
  params: {},
  loading: false,
  requesting: false,
  initialized: false,
  count: null,
  error: null,
  errorDetail: null,
};

const parseError = (err: AxiosError) => {
  return {
    error: err.message,
    data: err.response && err.response.data,
  };
};

interface ActionReturnType {
  type: string;
  data?: any;
}

class Base {
  APP_NAME = '';
  IS_NOTIFY = false;
  PRETTY_NAME = '';
  URL = '';

  MAKE_REQUEST = '';

  GET_REQUEST = '';
  GET_SUCCESS = '';
  GET_ERROR = '';

  GET_DETAIL_REQUEST = '';
  GET_DETAIL_SUCCESS = '';
  GET_DETAIL_ERROR = '';

  POST_REQUEST = '';
  POST_SUCCESS = '';
  POST_ERROR = '';

  PUT_REQUEST = '';
  PUT_SUCCESS = '';
  PUT_ERROR = '';

  PATCH_REQUEST = '';
  PATCH_SUCCESS = '';
  PATCH_ERROR = '';

  DELETE_REQUEST = '';
  DELETE_SUCCESS = '';
  DELETE_ERROR = '';

  SET_GET_PARAMS = '';
  SET_DETAIL = '';

  RESET = '';
  RESET_DETAIL = '';

  constructor(appName: string, relativePath: string, options = { name: '', isNotify: false }) {
    this.APP_NAME = appName;
    this.IS_NOTIFY = true || options.isNotify;
    this.PRETTY_NAME = options.name || this.APP_NAME;

    this.URL = `${API_URL}${relativePath}`;

    this.MAKE_REQUEST = `${this.APP_NAME}/MAKE_REQUEST`;

    this.GET_REQUEST = `${this.APP_NAME}/GET_REQUEST`;
    this.GET_SUCCESS = `${this.APP_NAME}/GET_SUCCESS`;
    this.GET_ERROR = `${this.APP_NAME}/GET_ERROR`;

    this.GET_DETAIL_REQUEST = `${this.APP_NAME}/GET_DETAIL_REQUEST`;
    this.GET_DETAIL_SUCCESS = `${this.APP_NAME}/GET_DETAIL_SUCCESS`;
    this.GET_DETAIL_ERROR = `${this.APP_NAME}/GET_DETAIL_ERROR`;

    this.POST_REQUEST = `${this.APP_NAME}/POST_REQUEST`;
    this.POST_SUCCESS = `${this.APP_NAME}/POST_SUCCESS`;
    this.POST_ERROR = `${this.APP_NAME}/POST_ERROR`;

    this.PUT_REQUEST = `${this.APP_NAME}/PUT_REQUEST`;
    this.PUT_SUCCESS = `${this.APP_NAME}/PUT_SUCCESS`;
    this.PUT_ERROR = `${this.APP_NAME}/PUT_ERROR`;

    this.PATCH_REQUEST = `${this.APP_NAME}/PATCH_REQUEST`;
    this.PATCH_SUCCESS = `${this.APP_NAME}/PATCH_SUCCESS`;
    this.PATCH_ERROR = `${this.APP_NAME}/PATCH_ERROR`;

    this.DELETE_REQUEST = `${this.APP_NAME}/DELETE_REQUEST`;
    this.DELETE_SUCCESS = `${this.APP_NAME}/DELETE_SUCCESS`;
    this.DELETE_ERROR = `${this.APP_NAME}/DELETE_ERROR`;

    this.SET_GET_PARAMS = `${this.APP_NAME}/SET_GET_PARAMS`;
    this.SET_DETAIL = `${this.APP_NAME}/SET_DETAIL`;

    this.RESET = `${this.APP_NAME}/RESET`;
    this.RESET_DETAIL = `${this.APP_NAME}/RESET_DETAIL`;

    this.reducer = this.reducer.bind(this);
  }

  reset(): ActionReturnType {
    return {
      type: this.RESET,
    };
  }

  resetDetail(): ActionReturnType {
    return {
      type: this.RESET_DETAIL,
    };
  }

  setGetParams(data: any): ActionReturnType {
    return {
      type: this.SET_GET_PARAMS,
      data,
    };
  }

  setDetail(data: any): ActionReturnType {
    return {
      type: this.SET_DETAIL,
      data,
    };
  }

  makeRequest(func: any, data: any, id: any): Record<string, unknown> {
    return {
      type: this.MAKE_REQUEST,
      func,
      data,
      id,
    };
  }

  getSuccess(data: any, append: any, options = {}): Record<string, unknown> {
    return {
      type: this.GET_SUCCESS,
      data,
      append,
      options,
    };
  }

  getError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.GET_ERROR,
      error,
      options,
    };
  }

  getDetailSuccess(data: any, options = {}): Record<string, unknown> {
    return {
      type: this.GET_DETAIL_SUCCESS,
      data,
      options,
    };
  }

  getDetailError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.GET_DETAIL_ERROR,
      error,
      options,
    };
  }

  postSuccess(data: any, options = {}): Record<string, unknown> {
    return {
      type: this.POST_SUCCESS,
      data,
      options,
    };
  }

  postError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.POST_ERROR,
      error,
      options,
    };
  }

  putSuccess(data: any, options = {}): Record<string, unknown> {
    return {
      type: this.PUT_SUCCESS,
      data,
      options,
    };
  }

  putError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.PUT_ERROR,
      error,
      options,
    };
  }

  patchSuccess(data: any, options = {}): Record<string, unknown> {
    return {
      type: this.PATCH_SUCCESS,
      data,
      options,
    };
  }

  patchError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.PATCH_ERROR,
      error,
      options,
    };
  }

  deleteSuccess(data: any, options = {}): Record<string, unknown> {
    return {
      type: this.DELETE_SUCCESS,
      data,
      options,
    };
  }

  deleteError(error: any, options = {}): Record<string, unknown> {
    return {
      type: this.DELETE_ERROR,
      error,
      options,
    };
  }

  notify = (options: any) => {
    if (options && options.notify && options.notify[options.status]) {
      return notification(options.notify[options.status], options.status);
    }
    return {
      type: 'IGNORE_THIS',
    };
  };

  reducer(state = DEFAULT_STATE, action: any) {
    switch (action.type) {
      case this.RESET: {
        return {
          ...DEFAULT_STATE,
        };
      }

      case this.RESET_DETAIL: {
        return {
          ...state,
          detail: DEFAULT_STATE.detail,
        };
      }

      case this.SET_GET_PARAMS: {
        return {
          ...state,
          params: action.data,
        };
      }

      case this.SET_DETAIL: {
        return {
          ...state,
          detail: action.data,
        };
      }

      case this.MAKE_REQUEST: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }

      case this.GET_REQUEST: {
        return {
          ...state,
          lastFetch: Math.floor(Date.now() / 1000),
          loading: true,
          error: null,
        };
      }

      case this.GET_SUCCESS: {
        let list = [];
        if (action.append) {
          list = [...state.list, ...((action.data && action.data.results) || action.data || [])];
        } else {
          list = (action.data && action.data.results) || action.data || [];
        }

        return {
          ...state,
          loading: false,
          initialized: true,
          url: action.data.next,
          list,
          count: action.data && action.data.count,
          error: null,
        };
      }

      case this.GET_ERROR: {
        return {
          ...state,
          error: action.error,
          loading: false,
          initialized: true,
        };
      }

      case this.GET_DETAIL_REQUEST: {
        return {
          ...state,
          loading: true,
          errorDetail: null,
        };
      }

      case this.GET_DETAIL_SUCCESS: {
        const list: any[] = [...state.list];
        if (Array.isArray(list)) {
          list.unshift(action.data);
        }

        const data = {
          ...state,
          loading: false,
          initialized: true,
          errorDetail: null,
          list: sortBy(
            uniqBy(list, (x: any) => x.id),
            (x: any) => x.id,
          ).reverse(),
        };

        // skip_detail is from the websocket and don't want to replace
        // current detail if any
        if (!(action.options && action.options.skip_detail)) {
          data.detail = action.data;
        }
        return data;
      }

      case this.GET_DETAIL_ERROR: {
        return {
          ...state,
          errorDetail: action.error,
          loading: false,
          initialized: true,
        };
      }

      case this.POST_REQUEST: {
        return {
          ...state,
          loading: true,
          error: null,
        };
      }

      case this.POST_SUCCESS: {
        const list: any[] = [...state.list];
        if (Array.isArray(list)) {
          list.unshift(action.data);
        }
        return {
          ...state,
          loading: false,
          detail: action.data,
          error: null,
          list: sortBy(
            uniqBy(list, (x: any) => x.id),
            (x: any) => x.id,
          ).reverse(),
        };
      }

      case this.POST_ERROR: {
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      }

      case this.PUT_REQUEST: {
        return { ...state, loading: true };
      }

      case this.PUT_SUCCESS: {
        let list: any[] = [...state.list];
        if (Array.isArray(list)) {
          list = findAndReplace(list, { id: action.data.id }, action.data);
        }
        return {
          ...state,
          loading: false,
          detail: action.data,
          list: list,
          error: null,
        };
      }

      case this.PUT_ERROR: {
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      }

      case this.PATCH_REQUEST: {
        return { ...state, loading: true };
      }

      case this.PATCH_SUCCESS: {
        let list: any[] = [...state.list];
        if (Array.isArray(list)) {
          list = findAndReplace(list, { id: action.data.id }, action.data);
        }

        return {
          ...state,
          detail: action.data,
          loading: false,
          list: list,
          error: null,
        };
      }

      case this.PATCH_ERROR: {
        return {
          ...state,
          error: action.error,
          loading: false,
        };
      }

      default:
        return state;
    }
  }

  getRequest = (currentUrl?: string, append?: boolean, options: any = {}) => {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('getRequest ', null, null));

      const state = getState();
      const headers = getHeaders(state);

      const params = state[this.APP_NAME].params;
      let url = this.URL;
      if (!isEmpty(params)) {
        url = `${url}?${encodeUrl(params)}`;
      }

      url = currentUrl || url;

      // if (url.indexOf('http') !== -1) {
      //   // strip out the hostname to prevent (blocked:mixed-content) error
      //   const urlObj = new URL(url);
      //   url = url.replace(urlObj.origin, '');
      // }

      // Returning a promise that can be used by the caller
      return Axios({
        method: 'get',
        url,
        headers,
      })
        .then((resp: any) => {
          dispatch(this.getSuccess(resp.data, append));
          dispatch(this.notify({ ...options, status: 'success', method: 'get' }));
          return resp.data;
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * get`,
          });

          dispatch(this.getError(err));
          dispatch(
            this.notify({ ...options, status: 'error', response: err.message, method: 'get' }),
          );
          return parseError(err);
        });
    };
  };

  getDetailRequest = (id: string | number, options = {}) => {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('getDetailRequest', null, id));

      const url = `${this.URL}${id}/`;
      const state = getState();
      const headers = getHeaders(state);
      return Axios({
        method: 'get',
        url,
        headers,
      })
        .then((resp: any) => {
          dispatch(this.getDetailSuccess(resp.data, options));
          dispatch(this.notify({ ...options, status: 'success', method: 'getDetail' }));
          return resp.data;
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * getDetail`,
          });
          dispatch(this.getDetailError(err.message));
          dispatch(
            this.notify({
              ...options,
              status: 'error',
              response: err.message,
              method: 'getDetail',
            }),
          );
          return parseError(err);
        });
    };
  };

  postRequest(data: any, options = {}) {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('postRequest', data, null));

      const state = getState();
      const headers = getHeaders(state);
      const url = this.URL;

      return Axios({
        method: 'post',
        url,
        data,
        headers,
      })
        .then((resp: any) => {
          dispatch(this.postSuccess(resp.data));
          dispatch(this.notify({ ...options, status: 'success', method: 'post' }));
          return resp.data;
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * post`,
          });
          dispatch(
            this.notify({ ...options, status: 'error', response: err.message, method: 'post' }),
          );
          return parseError(err);
        });
    };
  }

  putRequest(data: any, id: string, options = {}) {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('putRequest', data, id));

      const state = getState();
      const headers = getHeaders(state);
      const url = `${this.URL}${id || data.id}/`;

      return Axios({
        method: 'put',
        url,
        data,
        headers,
      })
        .then((resp: any) => {
          dispatch(this.putSuccess(resp.data));
          dispatch(this.notify({ ...options, status: 'success', method: 'patch' }));
          return resp.data;
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * put`,
          });
          dispatch(
            this.notify({ ...options, status: 'error', response: err.message, method: 'put' }),
          );
          return parseError(err);
        });
    };
  }

  patchRequest(
    data: any,
    id: string | number | null,
    options: { url?: null | string; notify?: any } = { url: null },
  ) {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('patchRequest', data, id));

      const state = getState();
      const headers = getHeaders(state);
      // Auth user condition
      const url = options && options.url ? options.url : `${this.URL}${id || data.id}/`;

      return Axios({
        method: 'patch',
        url,
        data,
        headers,
      })
        .then((resp: any) => {
          dispatch(this.patchSuccess(resp.data));
          dispatch(this.notify({ ...options, status: 'success', method: 'patch' }));
          return resp.data;
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * patch`,
          });
          dispatch(this.patchError(err.message));
          dispatch(
            this.notify({ ...options, status: 'error', response: err.message, method: 'patch' }),
          );
          return parseError(err);
        });
    };
  }

  deleteRequest(id: string | number, options = {}) {
    return (dispatch: any, getState: any) => {
      dispatch(this.makeRequest('deleteRequest', id, null));

      const state = getState();
      const headers = getHeaders(state);
      const url = `${this.URL}${id}/`;

      return Axios({
        method: 'delete',
        url,
        headers,
      })
        .then(() => {
          dispatch(this.setDetail({}));
          dispatch(this.getRequest());
          dispatch(this.notify({ ...options, status: 'success', method: 'delete' }));
          return { success: true };
        })
        .catch((err: AxiosError) => {
          log.actionError(err, {
            app: this.APP_NAME,
            error: generateErrorDict(err),
            url,
            function: `${this.APP_NAME} * deleteDetail`,
          });
          dispatch(
            this.notify({ ...options, status: 'error', method: 'delete', response: err.message }),
          );
          return parseError(err);
        });
    };
  }

  selectGlobal = (state: any) => state[this.APP_NAME];

  makeSelectBase = (key: string) => {
    return createSelector(this.selectGlobal, globalState => globalState[key]);
  };
}

export default Base;
