import React, {
  FC,
  createContext,
  useContext,
  useReducer,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import { TwilioError } from 'twilio-video';

import { RecordingRules, RoomType, UserAuthType, ApiError, RequestApiType } from 'src/types';
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction,
} from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { API_URL } from 'src/utils/constants';
import Axios from 'src/utils/axios';
import useLocalStorage from 'src/hooks/useLocalStorage';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<void | undefined>;
  initialized: boolean;

  // Mbele
  user?: UserAuthType | null;
  setAuthUser(user: UserAuthType | null): void;
  request?: RequestApiType | null;
  setRequest(request: RequestApiType | null): void;
  requestError?: ApiError | null;
  setRequestError(error: ApiError | null): void;
  requestId: string;
  setRequestId: (value: string) => void;
}

export const StateContext = createContext<StateContextType | null>(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
const AppStateProvider: FC<PropsWithChildren<unknown>> = props => {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [requestId, setRequestId] = useState('');
  const [initialized, setInitialized] = useState<boolean>(false);

  const [user, setAuthUser] = useState<UserAuthType | null>(null);
  const [request, setRequest] = useState<RequestApiType | null>(null);
  const [requestError, setRequestError] = useState<ApiError | null>(null);

  const [authToken, _] = useLocalStorage('authToken', '');

  useEffect(() => {
    Axios.get(`${API_URL}/api/v1/rest-auth/user/`, {
      headers: { Authorization: `Token ${authToken}` },
    })
      .then(resp => {
        setInitialized(true);
        if (resp.data && resp.data.id) {
          setAuthUser(resp.data);
        }
      })
      .catch(() => {
        setInitialized(true);
      });
  }, []);

  const contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    setAuthUser,
    user,
    request,
    setRequest,
    requestError,
    setRequestError,
    requestId,
    setRequestId,
    initialized,
    getToken: async (identity: string) => {
      return Axios({
        method: 'post',
        url: `${API_URL}/gct/`,
        data: { identity, room_uuid: window.room_uuid },
      })
        .then(resp => {
          return resp.data && resp.data.token;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateRecordingRules: async (room_sid, rules) => {
      return Axios({
        method: 'post',
        url: `${API_URL}/record-video/`,
        data: { room_sid, rules, room_uuid: window.room_uuid },
      })
        .then(resp => {
          const data = resp.data;
          if (data?.error) {
            const errMsg =
              data.error?.message || data.error || 'There was an error updating recording rules';

            setError(errMsg);

            const recordingError = new Error(errMsg);
            recordingError.code = data.error?.code;
            return Promise.reject(recordingError);
          }
        })
        .catch(err => {
          setError(err);
        });
    },
  } as StateContextType;

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
};

export function useAppState(): StateContextType {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

export default AppStateProvider;
