import clsx from 'clsx';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import ChatWindow from 'src/components/Chat/ChatWindow';
import ParticipantList from 'src/components/ParticipantList/ParticipantList';
import MainParticipant from 'src/components/MainParticipant/MainParticipant';
import BackgroundSelectionDialog from 'src/components/BackgroundSelectionDialog/BackgroundSelectionDialog';
import useChatContext from 'src/hooks/useChatContext/useChatContext';
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext';
import { useTool } from 'src/hooks';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${
    theme.sidebarMobileHeight + theme.sidebarMobilePadding * 2 + theme.participantBorderWidth
  }px`;
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: {
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px`,
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      height: '100%',
      width: '100%',
    },
  };
});

function ToolIframe() {
  const { toolUrl, view } = useTool();
  const classes = useStyles();
  return (
    <iframe
      src={toolUrl || ''}
      title={`${view} Session`}
      className={classes.infoContainer}
      frameBorder="0"
      loading="lazy"
    />
  );
}

export default function Room(): JSX.Element {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen } = useVideoContext();
  const { view } = useTool();
  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
      })}
    >
      {view !== 'default' ? <ToolIframe /> : <MainParticipant />}
      <ParticipantList />
      <ChatWindow />
      <BackgroundSelectionDialog />
    </div>
  );
}
