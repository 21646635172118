import React from 'react';

// Material UI
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App
// import MethodComponent from './Method';
import EmailComponent from './Email';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: 100,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      width: 280,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

interface MainProps {
  onClose: VoidFunction;
  onSuccess?: VoidFunction;
  setIsEmail: (value: boolean) => void;
  setIsLogin: (value: boolean) => void;
  isEmail: boolean;
  isLogin: boolean;
}

export default function Main({
  onSuccess,
  isEmail,
  setIsEmail,
  setIsLogin,
  isLogin,
}: MainProps): JSX.Element {
  const classes = useStyles();

  let headerTitle = 'Login';
  let otherContent = <div />;
  let subtitle = '';

  if (isLogin) {
    headerTitle = 'Log in to Mbele';
    subtitle = 'Manage your account, check notifications, give and get feedback, and more.';
    otherContent = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          Don't have an accounts?{' '}
          <Button
            id="toggle-login-button"
            color="primary"
            size="small"
            onClick={() => {
              setIsEmail(false);
              setIsLogin(!isLogin);
            }}
          >
            Sign up
          </Button>
        </div>

        <div>
          Forgotten Your Password?{' '}
          <Button
            id="toggle-login-button"
            color="primary"
            size="small"
            href="/accounts/password/reset/"
          >
            Reset Password
          </Button>
        </div>
      </div>
    );
  } else {
    headerTitle = 'Sign up for Mbele';
    subtitle = 'Create a profile, upload video to give or get feedback, and more.';
    otherContent = (
      <div>
        Already have an account?{' '}
        <Button
          id="toggle-login-button"
          color="primary"
          size="small"
          onClick={() => {
            setIsEmail(false);
            setIsLogin(!isLogin);
          }}
        >
          Log in
        </Button>
      </div>
    );
  }

  return (
    <form id="signup-form" className={classes.form} noValidate data-test-id="signup-form">
      <Grid justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <Typography id="header-title" variant="h5" gutterBottom align="center">
            {headerTitle}
          </Typography>

          <div className="tw-my-2 tw-text-center">{subtitle}</div>
        </Grid>
      </Grid>

      {/* {!isEmail ? (
        <MethodComponent
          onClose={onClose}
          onSuccess={onSuccess}
          setIsEmail={setIsEmail}
          isLogin={isLogin}
        />
      ) : ( */}
      <EmailComponent onSuccess={onSuccess} isLogin={isLogin} />

      <Grid justifyContent="center" style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={12} md={12}>
          {otherContent}
        </Grid>
      </Grid>
    </form>
  );
}
